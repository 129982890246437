import React from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Image from 'reusecore/src/elements/Image';
import Container from '../../../components/UI/Container';
import BannerWrapper from './banner.style';

const BannerSection = ({
  row,
  contentArea,
  imageArea,
  greetingStyle,
  nameStyle,
  aboutStyle,
  roleWrapper,
  component: { preTagline, tagline, subtext, image },
}) => {
  return (
    <BannerWrapper id="banner_section">
      <Container noGutter mobileGutter width="1200px">
        <Box {...row}>
          <Box {...contentArea}>
            <Heading content={preTagline} {...greetingStyle} />
            <Heading content={tagline} {...nameStyle} />
            <Box {...roleWrapper}>
              <Text content={subtext} {...aboutStyle} />
            </Box>
          </Box>
          <Box {...imageArea} className="image_area">
            <Image src={image.url} alt="Mat Helme" />
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  row: PropTypes.object,
  contentArea: PropTypes.object,
  imageArea: PropTypes.object,
  greetingStyle: PropTypes.object,
  nameStyle: PropTypes.object,
  aboutStyle: PropTypes.object,
  roleWrapper: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'stretch',
  },
  contentArea: {
    width: ['100%', '100%', '50%', '40%'],
    p: ['65px 0 80px 0', '65px 0 80px 0', '80px 0 60px 0', '0'],
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  imageArea: {
    width: ['100%', '100%', '50%', '60%'],
    flexBox: true,
    alignItems: 'middle',
    maxHeight: 'calc(90vh - 150px)',
    pl: '10%',
  },
  greetingStyle: {
    as: 'h3',
    color: '#fff',
    fontSize: ['30px', '30px', '35px', '50px', '50px'],
    fontWeight: '500',
    mb: '8px',
    lineHeight: '1em',
  },
  nameStyle: {
    as: 'h2',
    color: '#fff',
    fontSize: ['38px', '38px', '44px', '60px', '80px'],
    fontWeight: '800',
    mb: '6px',
    lineHeight: '1em',
  },
  roleWrapper: {
    flexBox: true,
    mt: '2em',
  },
  aboutStyle: {
    fontSize: ['17px', '17px', '22px', '25px', '20px'],
    fontWeight: '400',
    color: '#fff',
    lineHeight: '1.5',
    mb: '50px',
  },
};

export default BannerSection;
