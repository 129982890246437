import React from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Container from '../../../components/UI/Container';
import {
  AwardSectionWrapper,
} from './about.style';

const AboutSection = ({
  secTitleWrapper,
  secDescription,
  component,
}) => {

  return (
    <AwardSectionWrapper id="about_section">
      <Container noGutter mobileGutter width="1200px">
        <Box {...secTitleWrapper}>
          <Text
            {...secDescription}
            content={(
              <div dangerouslySetInnerHTML={{ __html: component.text }} />
            )}
          />
        </Box>

      </Container>
    </AwardSectionWrapper>
  );
};

AboutSection.propTypes = {
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  awardLogoStyle: PropTypes.object,
  awardNameStyle: PropTypes.object,
  awardDetailsStyle: PropTypes.object,
  awardeeLogoStyle: PropTypes.object,
  awardeeNameStyle: PropTypes.object,
  awardDateStyle: PropTypes.object,
};

AboutSection.defaultProps = {
  secTitleWrapper: {},
  secTitle: {
    fontSize: ['22px', '26px', '26px', '30px', '40px'],
    fontWeight: '600',
    color: '#302b4e',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '40px'],
  },
  secDescription: {
    fontSize: ['16px', '19px'],
    fontWeight: '400',
    color: '#fff',
    lineHeight: '1.5',
    mb: '0',
  },
  awardLogoStyle: {
    ml: 'auto',
    mr: 'auto',
    mb: '25px',
  },
  awardNameStyle: {
    fontSize: ['16px', '16px', '18px', '20px'],
    fontWeight: '600',
    color: '#302b4e',
    lineHeight: '1.35',
    textAlign: 'center',
    mb: '0',
  },
  awardDetailsStyle: {
    fontSize: ['15px', '15px', '15px', '16px'],
    color: '#43414e',
    lineHeight: '1.5',
    textAlign: 'center',
    mb: '0',
  },
  awardeeNameStyle: {
    fontSize: '16px',
    color: '#9391a5',
    lineHeight: '1.35',
    fontWeight: '600',
    mb: '4px',
  },
  awardDateStyle: {
    fontSize: '12px',
    color: '#9391a5',
    lineHeight: '1.35',
    mb: '0',
  },
};

export default AboutSection;
