import React from 'react';
import BannerSection from 'common/src/containers/Portfolio/Banner';
import HTMLSection from "common/src/containers/Portfolio/About";
import StageBlock from "common/src/containers/Portfolio/Services";
import QuadImageBlock from 'common/src/containers/App/PartnerHistory';
import FeatureArticleBlock from 'common/src/containers/SaasTwo/Partner';
import CardsBlock from "common/src/containers/Portfolio/Serverless";
import CallToAction from 'common/src/containers/Portfolio/CallToAction';

export default ({ components }) => components.map((component) => {
  switch (component.internal.type) {
    case 'DatoCmsComponentBanner':
      return <BannerSection component={component} />;
    case 'DatoCmsComponentHtmlBlock':
      return <HTMLSection component={component} />;
    case 'DatoCmsComponentStageBlock':
      return <StageBlock component={component} />;
    case 'DatoCmsComponentQuadImageBlock':
      return <QuadImageBlock component={component} />;
    case 'DatoCmsComponentFeaturedArticle':
      return <FeatureArticleBlock component={component} />;
    case 'DatoCmsComponentCardsBlock':
      return <CardsBlock component={component} />;
    case 'DatoCmsComponentCtaBlock':
      return <CallToAction component={component} />;
    default:
      return null;
  }
});
