import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import Image from 'reusecore/src/elements/Image';
import Container from '../../../components/UI/Container';

import PartnerSectionWrapper from './partner.style';

const PartnerSection = ({
  row,
  col,
  title,
  description,
  button,
  textArea,
  imageArea,
  sectionSubTitle,
  component,
}) => {
  return (
    <PartnerSectionWrapper>
      <Container>
        <Box {...row}>
          <Box {...col} {...imageArea}>
            <Image src={component.image.url} alt="Domain Image" />
          </Box>
          <Box {...col} {...textArea}>
            <Text
              {...sectionSubTitle}
              content={component.articleType}
            />
            <Heading
              {...title}
              content={component.title}
            />
            <Text
              {...description}
              content={component.description}
            />
            <Box>
              <Link target="_self" href={component.url}>
                <Button {...button} title="LEARN MORE" />
              </Link>
            </Box>
          </Box>
        </Box>
      </Container>
    </PartnerSectionWrapper>
  );
};

PartnerSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object,
};

PartnerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  imageAreaRow: {
    flexDirection: 'row-reverse',
  },
  col: {
    pr: '15px',
    pl: '15px',
  },
  textArea: {
    width: ['100%', '100%', '55%', '50%', '42%'],
  },
  imageArea: {
    width: ['100%', '100%', '45%', '50%', '58%'],
    mb: ['40px', '40px', '0', '0', '0'],
  },
  title: {
    fontSize: ['26px', '30px', '30px', '44px', '44px'],
    fontWeight: '400',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: '15px',
    lineHeight: '1.25',
  },
  sectionSubTitle: {
    content: 'TRUSTED PARTNERS',
    as: 'span',
    textAlign: 'left',
    fontSize: '14px',
    letterSpacing: '0.13em',
    fontWeight: '700',
    color: '#fff',
    mb: '15px',
    display: 'block',
    textAlign: ['center', 'left'],
  },
  description: {
    fontSize: '16px',
    color: '#fff',
    lineHeight: '1.75',
    mb: '33px',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    background: '#3444f1',
    minWidth: '150px',
  },
};

export default PartnerSection;
