import React from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Card from 'reusecore/src/elements/Card';
import Image from 'reusecore/src/elements/Image';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import FeatureBlock from '../../../components/FeatureBlock';
import Container from '../../../components/UI/Container';
import PartnerHistoryWrapper, { CounterUpArea } from './partnerHistory.style';
import BackgroundImg from '../../../assets/image/app/partner-bg.png';

const vendorImageRenderer  = (vendor) => {
  if (vendor.landingPage) {
    return (
      <a href={vendor.landingPage.slug}>
        <Image src={vendor.logo.url} alt={vendor.vendorName} />
        <Text content={vendor.vendorName} />
      </a>
    );
  }


  return (
    <React.Fragment>
      <Image src={vendor.logo.url} alt={vendor.vendorName} />
      <Text content={vendor.vendorName} />
    </React.Fragment>
  )
};

const PartnerHistory = ({
  row,
  col,
  cardStyle,
  title,
  description,
  cardArea,
  component
}) => {
  console.log(component);
  return (
    <PartnerHistoryWrapper id="partners">
      <Image
        src={BackgroundImg}
        className="backgroungImg"
        alt="backgroungImg"
      />
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col} style={{ flexDirection: 'column' }}>
            <FeatureBlock
              title={<Heading content={component.title} {...title} />}
              description={<Text content={ component.text } {...description} />}
            />
          </Box>
          <Box className="col" {...col} {...cardArea}>
            <CounterUpArea>
              {
                component.vendors.map((vendor) => (
                  <Card className="card card-image" {...cardStyle}>
                    { vendorImageRenderer(vendor) }
                  </Card>
                ))
              }
            </CounterUpArea>
          </Box>
        </Box>
      </Container>
    </PartnerHistoryWrapper>
  );
};

// Partner style props
PartnerHistory.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  cardStyle: PropTypes.object,
};

// Partner default style
PartnerHistory.defaultProps = {
  // Partner section row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // Partner section col default style
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, 1 / 2, 1 / 2, 1 / 2, 1 / 2],
    flexBox: true,
    alignSelf: 'center',
  },
  // Card default style
  cardStyle: {
    p: '53px 40px 35px',
    borderRadius: '10px',
    boxShadow: '0px 8px 20px 0px rgba(16, 66, 97, 0.07)',
  },
  // Partner section title default style
  title: {
    fontSize: ['24px', '26px', '30px', '36px', '48px'],
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '-0.010em',
    mb: '30px',
    maxWidth: ['100%', '100%', '100%', '490px', '490px'],
    textAlign: ['center', 'left'],
  },
  // Partner section description default style
  description: {
    fontSize: '1.05em',
    color: '#343d48cc',
    lineHeight: '2.1',
    mb: '15px',
    textAlign: ['center', 'left'],
  },
  cardArea: {
    pl: [0, 0, '40px', 0, 0],
  },
};

export default PartnerHistory;
